import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import PrivateRoute from "./Routes";

import SignIn from "../components/register-or-login/SignIn";

import Dashboard from "../components/dashboard/Dashboard";
import Academy from "../components/academy/Academy";
import BookingReports from "../components/reports/BookingReports";
import TransactionReport from "../components/reports/TransactionReport";
import BulkBooking from "../components/bulk-booking/BulkBooking";
import AddBulkBooking from "../components/bulk-booking/AddBulkBooking";
import BulkBookingDetail from "../components/bulk-booking/BulkBookingDetail";
import Blocking from "../components/blocking/Blocking";
import AddBlocking from "../components/blocking/AddBlocking";
import BlockingDetail from "../components/blocking/BlockingDetail";
import SettingsMain from "../components/settings/SettingsMain";
import ProductsMain from "../components/rates&inventory/ProductsMain";
import UpdatePricing from "../components/rates&inventory/UpdatePricing";
import AddProduct from "../components/rates&inventory/AddProduct";
import MembershipMain from "../components/membership/MembershipMain";
import AddPackage from "../components/membership/AddPackage";
import EnrolledMemberships from "../components/membership/EnrolledMemberships";
import AddMembership from "../components/membership/AddMembership";
import WalletPackage from "../components/wallet/WalletPackage";
import WalletDetails from "../components/wallet/WalletDetails";
import WalletHistory from "../components/wallet/WalletHistory";
import Home from "../components/website/Home";
import BookNow from "../components/website/BookNow";
import MembershipsPage from "../components/website/MembershipsPage";
import AcademyPage from "../components/website/AcademyPage";
import TechPage from "../components/website/TechPage";
import EventsPage from "../components/website/EventsPage";
import ConsultancyPage from "../components/website/ConsultancyPage";
import UserManagement from "../components/userManagement/UserManagement";
import OccupancyAnalytics from "../components/analytics/OccupancyAnalytics";
import SlotWiseOccupancy from "../components/analytics/SoltWiseOccupancy";
import BatchManagement from "../components/academy/batchManagement/BatchManagement";
import StudentManagement from "../components/academy/studmentManagement/StudentManagement";
import AttendanceManagement from "../components/academy/attendanceManagement/AttendanceManagement";
import ExpenseManagement from "../components/accounts/ExpenseManagement";
import ReceiptManagement from "../components/accounts/ReceiptManagement";
import CashBook from "../components/accounts/CashBook";
import TrialManagement from "../components/academy/trialManagement/TrialManagement";
import NonCashBook from "../components/accounts/NonCashBook";
import AcademyAnalytics from "../components/analytics/AcademyAnalytics";
import LeadAnalytics from "../components/analytics/LeadAnalytics";
import TransactionAnalytics from '../components/analytics/TransactionAnalytics';
import DateWiseTransactionAnalytics from "../components/analytics/DateWiseTransactionAnalytics";
import Badminton from "../components/website/Badminton";
import Futsal from "../components/website/Futsal";
import BoxCricket from "../components/website/BoxCricket";
import Swimming from "../components/website/Swimming";
import PickleBall from "../components/website/PickleBall";
import CloseLeads from "../components/customerManagement/CloseLeads";
import OpenLeads from "../components/customerManagement/OpenLeads";
import StockMaster from "../components/rates&inventory/stockMaster/StockMaster";
import StockRegister from "../components/rates&inventory/stockRegister/StockRegister";
import AcademyStockRegister from "../components/rates&inventory/academyStockRegister/AcademyStockRegister";
import CafeStockMaster from "../components/cafe/stockMaster/CafeStockMaster";
import CafeStockRegister from "../components/cafe/stockRegister/CafeStockRegister";
import CategoryManagement from "../components/settings/CategoryManagement";
import TestingMain from '../components/testing/testingMain';
import StudentArchive from "../components/academy/studmentManagement/StudentArchieve";
import CouponManagement from "../components/settings/CouponManagement";
import BannerManagement from "../components/settings/bannerManagement";

import GSTAndCommission from "../components/settings/GSTAndCommissions";
import WeeklyDashboard from "../components/analytics/WeeklyDashboard";
import MonthlyDashboard from "../components/analytics/MonthlyDashboard";
import FlexiMembership from "../components/membership/flexMembership/FlexiMembershipMain";
import TableTennis from "../components/website/TableTennis";
import EnquiryReport from "../components/reports/EnquiryReport";
import BDReport from "../components/reports/BdReport";
import ReleaseReport from "../components/reports/ReleaseSlotReport";
import BlockingSummaryReport from "../components/reports/BlockingSummaryReport";
import TrialReport from "../components/reports/TrialReport";
import AmountDueReport from "../components/reports/AmountDueReport";
import ReleaseBlocking from "../components/blocking/ReleaseBlocking";
import Policies from "../components/website/Policies";
import Assetlink from "../components/AssetLinks";
import AssetApplelink from "../components/AssetAppleLink";
import NewAccountingReport from "../components/reports/NewAccountingReport";
import AppRedirect from "../components/AppRedirect";
import WalletUsuage from "../components/wallet/WalletUsuage";
import SchoolPage from "../components/website/SchoolPage";
import CustomerReportsData  from "../components/reports/CustomerReportsData";
import OtherBookingReport from "../components/reports/OtherBookingReport";
import InvestorReport from "../components/reports/InvestorReport";
import Partnership from "../components/website/Partnerships";
import AcademyTransactionReport from "../components/reports/AcademyTransactionReport";
export default function Routes() {
  return (
    <Switch>

      <Route exact path="/app" component={AppRedirect} />
      <Route exact path="/.well-known/apple-app-site-association" component={AssetApplelink} />
      <Route exact path="/.well-known/assetlinks.json" component={Assetlink} />
      <Route exact path='/' component={Home} />
      <Route exact path='/partnerships' component={Partnership} />
      <Route exact path='/bookNow' component={BookNow} />
      <Route exact path='/membershipsPage' component={MembershipsPage} />
      <Route exact path='/academyPage' component={AcademyPage} />
      <Route exact path='/techPage' component={TechPage} />
      <Route exact path='/eventsPage' component={EventsPage} />
      <Route exact path='/consultancyPage' component={ConsultancyPage} />
      <Route exact path='/schools' component={SchoolPage} />
      <Route exact path='/badminton' component={Badminton} />
      <Route exact path='/football-ground' component={Futsal} />
      <Route exact path='/cricket-ground' component={BoxCricket} />
      <Route exact path='/swimming' component={Swimming} />
      <Route exact path='/pickleBall' component={PickleBall} />
      <Route exact path='/policies' component={Policies} />
      <Route exact path='/tableTennis' component={TableTennis} />
      <PrivateRoute exact path='/extranet/testing' component={TestingMain} />
      <PrivateRoute exact path='/extranet/dashboard' component={Dashboard} />
      <PrivateRoute exact path='/extranet/academy' component={Academy} />
      <PrivateRoute exact path='/extranet/categoryManagement' component={CategoryManagement} />
      <PrivateRoute
        exact
        path='/extranet/bookingReports'
        component={BookingReports}
      />
      <PrivateRoute exact path='/extranet/transactionReports' component={TransactionReport} />
      <PrivateRoute exact path='/extranet/investorReports' component={InvestorReport} />
      <PrivateRoute exact path='/extranet/enquiryReport' component={EnquiryReport} /> 
      <PrivateRoute exact path='/extranet/bDReport' component={BDReport} /> 

      <PrivateRoute exact path='/extranet/trialReport' component={TrialReport} />
      <PrivateRoute exact path='/extranet/releaseReport' component={ReleaseReport} />
      <PrivateRoute exact path='/extranet/amountDueReport' component={AmountDueReport} />
      <PrivateRoute exact path='/extranet/otherBookingReport' component={OtherBookingReport} />
      
      <PrivateRoute exact path='/extranet/blockingSummaryReport' component={BlockingSummaryReport} />
      <PrivateRoute exact path='/extranet/newAccountingReports' component={NewAccountingReport} />
      <PrivateRoute exact path='/extranet/academyTransactionReports' component={AcademyTransactionReport} />
      <PrivateRoute
        exact
        path='/extranet/bulkBooking'
        component={BulkBooking}
      />
      <PrivateRoute exact path='/extranet/blocking' component={Blocking} />
      <PrivateRoute exact path='/extranet/coupons' component={CouponManagement} />
      <PrivateRoute exact path='/extranet/bannerManagement' component={BannerManagement} />
      <PrivateRoute
        exact
        path='/extranet/bulkBooking/:id'
        component={BulkBookingDetail}
      />
      <PrivateRoute
        exact
        path='/extranet/addbulkbooking'
        component={AddBulkBooking}
      />
      <PrivateRoute path='/extranet/addblocking' component={AddBlocking} />
      <PrivateRoute path='/extranet/releaseBlocking' component={ReleaseBlocking} />
      <PrivateRoute path='/extranet/blocking/:id' component={BlockingDetail} />
     
        <PrivateRoute
        path='/extranet/oldReport'
        component={CustomerReportsData}
      />
      <PrivateRoute path='/extranet/policies' component={SettingsMain} />
      <PrivateRoute path='/extranet/gstCommission' component={GSTAndCommission} />
      <PrivateRoute path='/extranet/couponManagement' component={CouponManagement} />
      <PrivateRoute path='/extranet/products' component={ProductsMain} />
      <PrivateRoute path='/extranet/stockMaster' component={StockMaster} />
      <PrivateRoute path='/extranet/stockRegister' component={StockRegister} />
      <PrivateRoute path='/extranet/academyStockRegister' component={AcademyStockRegister} />
      <PrivateRoute path='/extranet/cafeStockMaster' component={CafeStockMaster} />
      <PrivateRoute path='/extranet/cafeStockRegister' component={CafeStockRegister} />

      <PrivateRoute path='/extranet/slot_rates' component={UpdatePricing} />
      <PrivateRoute path='/extranet/addproduct' component={AddProduct} />
      <PrivateRoute path='/extranet/membership' component={MembershipMain} />
      <PrivateRoute path='/extranet/addpackage' component={AddPackage} />
      <PrivateRoute
        path='/extranet/enrolledMembership/:sportId'
        component={EnrolledMemberships}
      />
      <PrivateRoute path='/extranet/addmembership' component={AddMembership} />
      <PrivateRoute path='/extranet/flexiMembership' component={FlexiMembership} />
      <PrivateRoute path='/extranet/walletPackages' component={WalletPackage} />
      <PrivateRoute path='/extranet/walletUsuage' component={WalletUsuage} />

      <PrivateRoute path='/extranet/walletDetails' component={WalletDetails} />
      <PrivateRoute path='/extranet/walletHistory' component={WalletHistory} />

      <PrivateRoute path='/extranet/userManagement' component={UserManagement} />

      <PrivateRoute path='/extranet/occupancyAnalytics' component={OccupancyAnalytics} />
      <PrivateRoute path='/extranet/slotWiseOccupancy' component={SlotWiseOccupancy} />
      <PrivateRoute path='/extranet/academyAnalytics' component={AcademyAnalytics} />
      <PrivateRoute path='/extranet/leadAnalytics' component={LeadAnalytics} />
      <PrivateRoute path='/extranet/transactionAnalytics' component={TransactionAnalytics} />
      <PrivateRoute path='/extranet/dateWiseTransactionAnalytics' component={DateWiseTransactionAnalytics} />
      <PrivateRoute path='/extranet/weeklyDashboard' component={WeeklyDashboard} />
      <PrivateRoute path='/extranet/monthlyDashboard' component={MonthlyDashboard} />

      <PrivateRoute path='/extranet/batchManagement' component={BatchManagement} />
      <PrivateRoute path='/extranet/studentManagement' component={StudentManagement} />
      <PrivateRoute path='/extranet/attendanceManagement' component={AttendanceManagement} />
      <PrivateRoute path='/extranet/trialManagement' component={TrialManagement} />
      <PrivateRoute path='/extranet/studentArchives' component={StudentArchive} />

      <PrivateRoute path='/extranet/closeLeads' component={CloseLeads} />
      <PrivateRoute path='/extranet/openLeads' component={OpenLeads} />

      <PrivateRoute path='/extranet/expenses' component={ExpenseManagement} />
      <PrivateRoute path='/extranet/receipts' component={ReceiptManagement} />
      <PrivateRoute path='/extranet/cashBook' component={CashBook} />
      <PrivateRoute path='/extranet/nonCashBook' component={NonCashBook} />

      <Route path='/extranet/signIn' component={SignIn} />
      <Route path='/extranet'>
        <Redirect to={"/extranet/signIn"} />
      </Route>
      <Route>
        <Redirect to={"/"} />
      </Route>
    </Switch>
  );
}
