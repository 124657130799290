import React, { useState } from "react";
import ReactExport from "react-export-excel";
import { Button, Icon } from "semantic-ui-react";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const SheetData = (columns) => {
  return columns.map((column) => {
    return <ExcelColumn label={column.label} value={column.value} />;
  });
};

const ExportToExcel = ({ sheets, disabled, fileName, buttonName }) => {
  const [enableButton,setenableButton]=useState(true)
  // console.log("sheets",sheets)
  // console.log("disabled",disabled)
  // console.log("fileName",fileName)
  // console.log("buttonName",buttonName)
 const onClickButton =()=>{
  // console.log("onclick on download excel button")
  setenableButton(false)

 }
  return (
    <ExcelFile
      filename={fileName}
      element={
        <Button disabled={disabled && enableButton} onClick={()=>{onClickButton()}}>
          <Icon name={"download"} />
          {buttonName}
        </Button>
      }
    >
      {sheets.map((sheet) => {
        return (
          <ExcelSheet data={sheet.dataSet} name={sheet.name}>
            {SheetData(sheet.columns)}
          </ExcelSheet>
        );
      })}
    </ExcelFile>
  );
};

export default ExportToExcel;
