import React, { useEffect, useContext, useState } from "react";
import {
  Button,
  Icon,
  Table,
} from "semantic-ui-react";
import { colors } from "@material-ui/core";
import Toast from "../../generic/Toast";
import { getCafeStockRegister, getCafeProductHistory } from "../../../apiclients/ProductApiClient";
import { AppContext } from "../../../AppContext";
import GenericLoader from "../../generic/GenericLoader";
import AddProductToCafeRegister from "./AddProductToCafeRegister";
import EditProductToCafeRegister from './EditProductToCafeRegister'
import { ROLES } from '../../../utils/constants';
import CustomModal from "../../generic/CustomModal";
import RentSaleProducts from "../../dashboard/booked-booking/RentSaleProducts";
import DiscardCafeProduct from "./DiscardCafeProduct";
import CafeStockHistory from "./CafeStockHistory";
import moment from "moment";
import SaleCafeProducts from "../../dashboard/booked-booking/SaleCafeProducts";
import ExportToExcel from "../../generic/ExportToExcel";
import UpdatePrice from "../../rates&inventory/stockRegister/UpdatePrice";

const CafeStockRegister = (props) => {
  const { history } = props;
  const context = useContext(AppContext);
  const user = context.user;
  const [stockRegister, setStockRegister] = useState([]);
  const [stockHistory, setStockHistory] = useState([]);
  const [addProduct, setAddProduct] = useState(false);
  const [editProduct, setEditProduct] = useState({ state: false, data: {} });
  const [selectedProduct, setSelectedProduct] = useState();
  const [priceModal, setPriceModal] = useState(false); // to show discard modal
  const [discardModal, setDiscardModal] = useState(false); // to show discard modal
  const [historyModal, setHistoryModal] = useState(false); // to show History modal

  const [showRentOrSell, setShowRentOrSell] = useState(false);
  const [loader, setLoader] = useState(false);
  const [reload, setReload] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [messageContent, setMessageContent] = useState({});

  const tableHeader = [
    { label: "Product Name", value: "productName" },
    { label: "Selling Price", value: (col) => ("Rs. " + col.sellingPrice) },
    { label: "ERP Stock", value: "productQuantity" },
    { label: "Actual Stock" },
    { label: "Matching ?" },
  ];
  const fileName = "Cafe Stock Register" + moment().format('MMMM Do YYYY');
  const sheetName = moment().format('MMMM Do YYYY, h_mm a');

  const sheets = [
    {
      name: sheetName,
      columns: tableHeader,
      dataSet: stockRegister,
    },
  ];

  const loadData = async () => {
    setisLoading(true);
    return getCafeStockRegister(
      context.selectedFacility,
      context.selectedArena.arenaId,
    ).then((response) => {
      const data = response.data;
      setStockRegister(data.data);
      setisLoading(false);
    });
  };

  const openHistoryModal = async (product) => {
    setisLoading(true);
    setSelectedProduct(product);
    return getCafeProductHistory(
      context.selectedFacility,
      context.selectedArena.arenaId,
      product.id
    ).then((response) => {
      const data = response.data;
      setStockHistory(data);
      setHistoryModal(true);
      setisLoading(false);
    });
  };



  const openDiscardModal = (product) => {
    setSelectedProduct(product);
    setDiscardModal(true);
  };

  const openPriceChangeModal = (product) => {
    setSelectedProduct(product);
    setPriceModal(true);
  };

  useEffect(() => {
    if (reload) {
      loadData().then(() => {
        setReload(false);
        setShowMessage(true);
        setTimeout(() => {
          setShowMessage(false);
        }, 2000);
      });
    }
  }, [reload]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadData();
  }, [context.selectedFacility, context.selectedArena.arenaId]);// eslint-disable-line react-hooks/exhaustive-deps

  return isLoading ? <GenericLoader /> : (
    <div style={{ padding: "16px" }}>
      <div className='dashboard' style={{ marginBottom: "16px" }}>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <div>
            <span>Cafe Stock Register</span>
          </div>
          <div style={{ alignItems: "center" }}>
            <span>
              <Button
                onClick={() => { setAddProduct(true) }}
                name={"Add Product to Stock Register"}
              >
                <Icon name={"add"} />
                Add Product/Stock
              </Button>

              <Button
                style={{
                  fontSize: "14px",
                  marginBottom: "8px",
                  backgroundColor: colors.orange["800"],
                  color: "#fff",
                }}
                onClick={() => setShowRentOrSell(true)}
                name={"Add Category"}
              >
                <Icon name={"shop"} />
                Sell Cafe Items
              </Button>
              <span>
                <ExportToExcel disabled={stockRegister.length === 0} sheets={sheets}
                  fileName={fileName}
                  buttonName="Download Report"
                />
              </span>
            </span>
          </div>
        </div>
      </div>
      {loader ? (
        <GenericLoader />
      ) : (
        <div style={{ paddingTop: "16px" }}>
          <Toast
            showMessage={showMessage}
            setShowMessage={setShowMessage}
            message={messageContent.message}
            messageHeader={messageContent.header}
            color={messageContent.color}
          />
          <Table celled padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>S.No</Table.HeaderCell>
                <Table.HeaderCell>Product Name</Table.HeaderCell>
                <Table.HeaderCell>Company</Table.HeaderCell>
                <Table.HeaderCell>Category</Table.HeaderCell>
                <Table.HeaderCell>Sub-Category</Table.HeaderCell>
                <Table.HeaderCell>Closing Stock</Table.HeaderCell>
                <Table.HeaderCell>Selling Price</Table.HeaderCell>
                <Table.HeaderCell>Product History</Table.HeaderCell>
                <Table.HeaderCell>Quick Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {stockRegister.map((x, index) => {
                return (
                  <Table.Row key={x.id} >
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>{x.productName}{

                      moment.duration(moment(new Date(x.lastUpdated.seconds * 1000)).diff(moment())).asHours() > -24
                        ? <div
                          style={{
                            backgroundColor: colors.teal["500"],
                            borderRadius: "12px",
                            color: "#fff",
                            textAlign: "center",
                            fontWeight: "500",
                            fontSize: "10px",
                            padding: "2px",
                            margin: "16px 0px 5px 0px"
                          }}
                        >Updated in Last 24 hours</div>
                        : ""}

                    </Table.Cell>
                    <Table.Cell>{x.companyName}</Table.Cell>
                    <Table.Cell>{x.category}</Table.Cell>
                    <Table.Cell>{x.subCategory}</Table.Cell>
                    <Table.Cell><b>
                      {
                        x.productQuantity < x.reorderLevel ?
                          <div
                            style={{
                              textDecoration: "underline",
                              color: colors.red[500],
                            }}
                          > {x.productQuantity} </div> :
                          x.productQuantity
                      } </b>
                    </Table.Cell>
                    <Table.Cell><b>Rs. {x.sellingPrice}/- </b>
                    </Table.Cell>
                    <Table.Cell>

                      <Button
                        style={{
                          fontSize: "12px",
                          marginBottom: "8px",
                          backgroundColor: colors.orange["800"],
                          color: "#fff",
                        }}
                        onClick={() => openHistoryModal(x)}
                      >
                        Show History
                      </Button>



                    </Table.Cell>
                    <Table.Cell
                      style={{
                        flex: 1,
                        justifyContent: "center",
                        flexDirection: "column",
                        display: "flex",
                      }}
                    >
                      <Button
                        style={{
                          padding: "8px",
                          fontSize: "12px",
                          marginBottom: "8px",
                        }}
                        disabled={user.role === ROLES.ADMIN || user.role === ROLES.ACCOUNTANT || user.role === ROLES.REGIONAL_MANAGER ? false : true}
                        onClick={() => openDiscardModal(x)}
                      >
                        Discard Stock
                      </Button>
                      <Button
                        style={{
                          padding: "8px",
                          fontSize: "12px",
                          marginBottom: "8px",
                        }}
                        disabled={user.role === ROLES.ADMIN || user.role === ROLES.REGIONAL_MANAGER || user.role === ROLES.QC_MANAGER ? false : true}
                        onClick={() => openPriceChangeModal(x)}
                      >
                        Update Price
                      </Button>
                      <Button
                        style={{
                          padding: "8px",
                          fontSize: "12px",
                          marginBottom: "8px",
                        }}
                        disabled={user.role === ROLES.ADMIN || user.role === ROLES.QC_MANAGER ? false : true}
                        onClick={() => setEditProduct({ state: true, data: x })}
                      >
                        Edit
                      </Button>
                    </Table.Cell>
                  </Table.Row>

                );
              })}
            </Table.Body>
          </Table>

          <br />

          How to Manage Cafe Stock Register? The video are of manaing Product stock register, but this can be viewed to learn about cafe.
          <Table celled padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Stock Module Overview</Table.HeaderCell>
                <Table.HeaderCell>Add Product to Register</Table.HeaderCell>
                <Table.HeaderCell>Sell, Discard, & Show history</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row key={0}>
                <Table.Cell><div className="player2">
                  <iframe width="200" height="100" src="https://www.youtube.com/embed/aHEhpG25AJ8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;" ></iframe>
                </div></Table.Cell>
                <Table.Cell><div className="player2">
                  <iframe width="200" height="100" src="https://www.youtube.com/embed/78sbJC6wYeA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"  ></iframe>
                </div></Table.Cell>
                <Table.Cell><div className="player2">
                  <iframe width="200" height="100" src="https://www.youtube.com/embed/SzhLeYphNMM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen;"  ></iframe>
                </div></Table.Cell>
              </Table.Row>
            </Table.Body>

          </Table>
        </div>

      )}
      {
        editProduct.state ? (
          <EditProductToCafeRegister
            history={history}
            showModal={setEditProduct}
            setReload={setReload}
            setMessage={setMessageContent}
            isLoading={setisLoading}
            data={editProduct.data}
          />
        ) : null
      }
      {addProduct ? (
        <AddProductToCafeRegister
          history={history}
          showModal={setAddProduct}
          setReload={setReload}
          setMessage={setMessageContent}
          isLoading={setisLoading}
        />
      ) : null}
      {showRentOrSell ? (
        <CustomModal
          header={"Sell Cafe Products"}
          content={
            <SaleCafeProducts
              facility={{
                ...context.selectedArena,
                id: context.selectedFacility,
              }}
              onSaveComplete={() => setReload(true)}
              setOpen={setShowRentOrSell}
              setType={() => setShowRentOrSell(false)}
              reload={() => setReload(true)}
              setMessage={setMessageContent}
            />
          }
          comp={true}
          setOpen={setShowRentOrSell}
          open={showRentOrSell}
        />
      ) : null}
      {discardModal ? (
        <DiscardCafeProduct
          showModal={setDiscardModal}
          setReload={setReload}
          setMessage={setMessageContent}
          isLoading={setisLoading}
          product={selectedProduct}
        />
      ) : null}
      {priceModal ? (
        <UpdatePrice
          showModal={setPriceModal}
          setReload={setReload}
          setMessage={setMessageContent}
          isLoading={setisLoading}
          product={selectedProduct}
          isCafe={true}
        />
      ) : null}


      {historyModal ? (
        <CafeStockHistory
          showModal={setHistoryModal}
          stockData={stockHistory}
          selectedProduct={selectedProduct}
        />
      ) : null}

    </div>
  );
};

export default CafeStockRegister;
