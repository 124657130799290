import React, { useEffect, useContext, useState } from "react";
import { Dropdown, Form, Icon, Table, Button } from "semantic-ui-react";
import { AppContext } from "../../AppContext";
import {
  getAllPolicy,
  updatePolicies,
} from "../../apiclients/BookingApiClient";
import GenericLoader from "../generic/GenericLoader";
import { ROLES } from "../../utils/constants";

const getSports = (selectedArena, arenaData) => {
  if (selectedArena && arenaData[selectedArena.arenaId]) {
    const dropdownSportsData = arenaData[selectedArena.arenaId].sports.map(
      (x, index) => ({
        key: x.sportId,
        value: x.sportId,
        text: x.sportName,
      })
    );
    return dropdownSportsData;
  }
  return [];
};


const SettingsMain = (props) => {
  const { selectedFacility, selectedArena, arenaData, user } = useContext(AppContext);

  const sports = getSports(selectedArena, arenaData);
  const [selectedSport, setSelectedSport] = useState(sports[0]);
  const [policyData, setPolicyData] = useState();
  const [editType, setEditType] = useState();
  const [cancellationData, setCancellationData] = useState();
  const [rescheduleData, setRescheduleData] = useState();
  const [playAllRescheduleData, setPlayAllRescheduleData] = useState();
  const [extendData, setExtendData] = useState();
  const [playAllExtendData, setPlayAllExtendData] = useState();
  const [partPaymentData, setPartPaymentData] = useState();
  const [maxParticipants, setMaxParticipants] = useState();
  const [playAllCancellationData, setPlayAllCancellationData] = useState();
  const [showLoader, setShowLoader] = useState();
  const [reload, setReload] = useState(true);

  useEffect(() => {
    if (!reload) {
      setReload(true);
    }
  }, [selectedArena]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (reload && selectedSport) {
      getAllPolicy(selectedFacility, selectedArena.arenaId, selectedSport.value)
        .then((resp) => {
          console.log("Reached");
          const data = resp && resp.data;
          if (data) {
            setPolicyData(data);
            setExtendData(data.extension);
            data.partPayment && setPartPaymentData(data.partPayment);
            setCancellationData(data.cancellation);
            setRescheduleData(data.reschedule);
            data.playAllCancellation && setPlayAllCancellationData(data.playAllCancellation);
            data.maxCapacity && setMaxParticipants(data.maxCapacity);
            data.playAllReschedule && setPlayAllRescheduleData(data.playAllReschedule);
            data.playAllAppExtension && setPlayAllExtendData(data.playAllAppExtension);
            
          }
          setReload(false);
        })
        .catch((error) => {
          setReload(false);
        });
    }
  }, [reload, selectedSport]);// eslint-disable-line react-hooks/exhaustive-deps

  const changeSport = (event, { value }) => {
    const data = sports.find((facility) => facility.value === value);
    setSelectedSport(data);
    setReload(true);
  };

  const getData = () => {
    if (isCancelEditing) {
      return cancellationData;
    } else if (isPlayAllCancelEditing) {
      return playAllCancellationData;
    } else if (isMaxCapacityEditing) {
      return maxParticipants;
    } else if (isReschduleEditing) {
      return rescheduleData;
    } else if (isPlayAllReschduleEditing) {
      return playAllRescheduleData;
    } else if (isExtendEditing) {
      return extendData;
    } else if (isPlayAllExtendEditing) {
      return playAllExtendData;
    } else if (isPartPayment) {
      return partPaymentData;
    }
  };
  const onPolicyUpdate = () => {
    setShowLoader(true);

    updatePolicies(
      selectedFacility,
      selectedArena.arenaId,
      selectedSport.value,
      editType,
      getData()
    ).then((resp) => {
      setShowLoader(false);
      setReload(true);
      setEditType(null);
      console.log("resp", resp);
    });
  };

  const updateCancelKeyValue = (key, value) => {
    const updatedData = cancellationData.rules.map((x) => {
      if (x.hoursBefore === key) {
        return { ...x, refund: +value };
      }
      return x;
    });
    setCancellationData({ ...cancellationData, rules: updatedData });
  };

  const updatePlayAllCancelKeyValue = (key, value) => {
    const updatedData = playAllCancellationData.rules.map((x) => {
      if (x.hoursBefore === key) {
        return { ...x, refund: +value };
      }
      return x;
    });
    setPlayAllCancellationData({ ...playAllCancellationData, rules: updatedData });
  };

  const userAllowed = user.role === ROLES.ADMIN;

  const isCancelEditing = editType === "cancellation" && userAllowed;
  const isPlayAllCancelEditing = editType === "playAllCancellation" && userAllowed;
  const isMaxCapacityEditing = editType === "maxCapacity" && userAllowed;
  const isReschduleEditing = editType === "reschedule" && userAllowed;
  const isExtendEditing = editType === "extension" && userAllowed;
  const isPlayAllExtendEditing = editType === "playAllAppExtension" && userAllowed;
  const isPartPayment = editType === "partPayment" && userAllowed;
  const isPlayAllReschduleEditing = editType === "playAllReschedule" && userAllowed;

  return (
    <div style={{ padding: "16px" }}>
      {showLoader || reload ? <GenericLoader /> : null}
      <div className='dashboard'>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <div>
            <span>Settings</span>
          </div>

          <Dropdown
            style={{ fontSize: "12px" }}
            onChange={changeSport}
            placeholder='Select...'
            openOnFocus
            selection
            value={selectedSport && selectedSport.value}
            options={sports}
          />
        </div>
      </div>
      {policyData ? (
        <div>
          <div
            style={{
              marginTop: "12px",
              marginBottom: "10px",
              fontSize: "15px",
              fontWeight: "600",
            }}
          >
            PlayAll Cancellation Policy
            <Icon
              onClick={() => setEditType("playAllCancellation")}
              name={"pencil alternate"}
              color={isCancelEditing ? "grey" : "orange"}
              style={{ marginLeft: "10px" }}
            />
          </div>
          <Table celled padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Timeline</Table.HeaderCell>
                <Table.HeaderCell>Refund percent</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {(isPlayAllCancelEditing
                ? playAllCancellationData.rules
                : policyData.playAllCancellation.rules
              ).map((x) => {
                return (
                  <Table.Row key={x.name}>
                    <Table.Cell>{x.name}</Table.Cell>
                    <Table.Cell>
                      {isPlayAllCancelEditing ? (
                        <Form
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <input
                            style={{ marginRight: "12px" }}
                            type='text'
                            onInput={(evt) =>
                              updatePlayAllCancelKeyValue(
                                x.hoursBefore,
                                evt.target.value
                              )
                            }
                            value={x.refund}
                            placeholder='e.g., type reason here'
                          />{" "}
                          %
                        </Form>
                      ) : (
                        x.refund
                      )}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          {isPlayAllCancelEditing ? (
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Button
                onClick={onPolicyUpdate}
                style={{
                  backgroundColor: "#e8632b",
                  color: "#fff",
                }}
              >
                Save
              </Button>
            </div>
          ) : null}
          <div
            style={{
              marginTop: "12px",
              marginBottom: "10px",
              fontSize: "15px",
              fontWeight: "600",
            }}
          >
            Cancellation Policy
            <Icon
              onClick={() => setEditType("cancellation")}
              name={"pencil alternate"}
              color={isCancelEditing ? "grey" : "orange"}
              style={{ marginLeft: "10px" }}
            />
          </div>
          <Table celled padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Timeline</Table.HeaderCell>
                <Table.HeaderCell>Refund percent</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {(isCancelEditing
                ? cancellationData.rules
                : policyData.cancellation.rules
              ).map((x) => {
                return (
                  <Table.Row key={x.name}>
                    <Table.Cell>{x.name}</Table.Cell>
                    <Table.Cell>
                      {isCancelEditing ? (
                        <Form
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <input
                            style={{ marginRight: "12px" }}
                            type='text'
                            onInput={(evt) =>
                              updateCancelKeyValue(
                                x.hoursBefore,
                                evt.target.value
                              )
                            }
                            value={x.refund}
                            placeholder='e.g., type reason here'
                          />{" "}
                          %
                        </Form>
                      ) : (
                        x.refund
                      )}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          {isCancelEditing ? (
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Button
                onClick={onPolicyUpdate}
                style={{
                  backgroundColor: "#e8632b",
                  color: "#fff",
                }}
              >
                Save
              </Button>
            </div>
          ) : null}
          <div
            style={{
              marginTop: "22px",
              marginBottom: "10px",
              fontSize: "15px",
              fontWeight: "600",
            }}
          >
            Reschedule Policy
            <Icon
              onClick={() => setEditType("reschedule")}
              name={"pencil alternate"}
              color={isReschduleEditing ? "grey" : "orange"}
              style={{ marginLeft: "10px" }}
            />
          </div>
          <Table celled padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Allowed</Table.HeaderCell>
                <Table.HeaderCell>Hours</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {/* {policyData.reschedule.map((x) => {
              return ( */}
              <Table.Row>
                {isReschduleEditing ? (
                  <Table.Cell>
                    <Dropdown
                      style={{ fontSize: "12px" }}
                      onChange={(event, { value }) => {
                        setRescheduleData((st) => ({
                          ...st,
                          isAllowed: value,
                        }));
                      }}
                      placeholder='Select...'
                      openOnFocus
                      selection
                      value={rescheduleData.isAllowed}
                      options={[
                        { key: "yes", text: "Yes", value: true },
                        { key: "no", text: "No", value: false },
                      ]}
                    />
                  </Table.Cell>
                ) : (
                  <Table.Cell>
                    {policyData.reschedule.isAllowed ? "Yes" : "No"}
                  </Table.Cell>
                )}
                {isReschduleEditing ? (
                  <Table.Cell>
                    <Form
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Before{" "}
                      <input
                        pattern='[0-9]*'
                        style={{ marginRight: "12px", marginLeft: "12px" }}
                        type='text'
                        onInput={(evt) => {
                          const numericValue = evt.target.validity.valid
                            ? evt.target.value
                            : rescheduleData.hourRemaining;

                          setRescheduleData((st) => ({
                            ...st,
                            hourRemaining: +numericValue,
                          }));
                        }}
                        value={rescheduleData.hourRemaining}
                        placeholder='e.g., numeric value'
                      />{" "}
                      hours
                    </Form>{" "}
                  </Table.Cell>
                ) : (
                  <Table.Cell>
                    Before {policyData.reschedule.hourRemaining} hours
                  </Table.Cell>
                )}
              </Table.Row>
              {/* );
            })} */}
            </Table.Body>
          </Table>
          {isReschduleEditing ? (
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Button
                onClick={onPolicyUpdate}
                style={{
                  backgroundColor: "#e8632b",
                  color: "#fff",
                }}
              >
                Save
              </Button>
            </div>
          ) : null}
          <div
            style={{
              marginTop: "22px",
              marginBottom: "10px",
              fontSize: "15px",
              fontWeight: "600",
            }}
          >
            PlayAll Reschedule Policy
            <Icon
              onClick={() => setEditType("playAllReschedule")}
              name={"pencil alternate"}
              color={isPlayAllReschduleEditing ? "grey" : "orange"}
              style={{ marginLeft: "10px" }}
            />
          </div>
          <Table celled padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Allowed</Table.HeaderCell>
                <Table.HeaderCell>Hours</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                {isPlayAllReschduleEditing ? (
                  <Table.Cell>
                    <Dropdown
                      style={{ fontSize: "12px" }}
                      onChange={(event, { value }) => {
                        setPlayAllRescheduleData((st) => ({
                          ...st,
                          isAllowed: value,
                        }));
                      }}
                      placeholder='Select...'
                      openOnFocus
                      selection
                      value={playAllRescheduleData.isAllowed}
                      options={[
                        { key: "yes", text: "Yes", value: true },
                        { key: "no", text: "No", value: false },
                      ]}
                    />
                  </Table.Cell>
                ) : (
                  <Table.Cell>
                    {policyData.playAllReschedule.isAllowed ? "Yes" : "No"}
                  </Table.Cell>
                )}
                {isPlayAllReschduleEditing ? (
                  <Table.Cell>
                    <Form
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      Before{" "}
                      <input
                        pattern='[0-9]*'
                        style={{ marginRight: "12px", marginLeft: "12px" }}
                        type='text'
                        onInput={(evt) => {
                          const numericValue = evt.target.validity.valid
                            ? evt.target.value
                            : playAllRescheduleData.hourRemaining;

                          setPlayAllRescheduleData((st) => ({
                            ...st,
                            hourRemaining: +numericValue,
                          }));
                        }}
                        value={playAllRescheduleData.hourRemaining}
                        placeholder='e.g., numeric value'
                      />{" "}
                      hours
                    </Form>{" "}
                  </Table.Cell>
                ) : (
                  <Table.Cell>
                    Before {policyData.playAllReschedule.hourRemaining} hours
                  </Table.Cell>
                )}
              </Table.Row>
            </Table.Body>
          </Table>
          {isPlayAllReschduleEditing ? (
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Button
                onClick={onPolicyUpdate}
                style={{
                  backgroundColor: "#e8632b",
                  color: "#fff",
                }}
              >
                Save
              </Button>
            </div>
          ) : null}
          <div
            style={{
              marginTop: "22px",
              marginBottom: "10px",
              fontSize: "15px",
              fontWeight: "600",
            }}
          >
            Extension Policy
            <Icon
              onClick={() => setEditType("extension")}
              name={"pencil alternate"}
              color={isExtendEditing ? "grey" : "orange"}
              style={{ marginLeft: "10px" }}
            />
          </div>
          <Table celled padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Allowed</Table.HeaderCell>
                <Table.HeaderCell>Discount</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  {isExtendEditing ? (
                    <Dropdown
                      style={{ fontSize: "12px" }}
                      onChange={(event, { value }) => {
                        setExtendData((st) => ({
                          ...st,
                          isAllowed: value,
                        }));
                      }}
                      placeholder='Select...'
                      openOnFocus
                      selection
                      value={extendData.isAllowed}
                      options={[
                        { key: "yes", text: "Yes", value: true },
                        { key: "no", text: "No", value: false },
                      ]}
                    />

                  ) : (
                    policyData.extension.isAllowed ? "Yes" : "No"
                  )}
                </Table.Cell>
                <Table.Cell>
                  {isExtendEditing ? (
                    <Form
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <input
                        style={{ marginRight: "12px" }}
                        type='text'
                        onInput={(evt) => {
                          const discount = evt.target.value;
                          setExtendData((st) => ({
                            ...st, discount
                          }))
                        }
                        }
                        value={extendData.discount}
                      />
                      %
                    </Form>
                  ) : (
                    policyData.extension.discount
                  )}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          {isExtendEditing ? (
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Button
                onClick={onPolicyUpdate}
                style={{
                  backgroundColor: "#e8632b",
                  color: "#fff",
                }}
              >
                Save
              </Button>
            </div>
          ) : null}

          <div
            style={{
              marginTop: "22px",
              marginBottom: "10px",
              fontSize: "15px",
              fontWeight: "600",
            }}
          >
            PlayAll App Extension Policy
            <Icon
              onClick={() => setEditType("playAllAppExtension")}
              name={"pencil alternate"}
              color={isPlayAllExtendEditing ? "grey" : "orange"}
              style={{ marginLeft: "10px" }}
            />
          </div>
          <Table celled padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Allowed</Table.HeaderCell>
                <Table.HeaderCell>Discount</Table.HeaderCell>
                <Table.HeaderCell>Extension Window in Mins</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  {
                  isPlayAllExtendEditing ? (
                    <Dropdown
                      style={{ fontSize: "12px" }}
                      onChange={(event, { value }) => {
                        setPlayAllExtendData((st) => ({
                          ...st,
                          isAllowed: value,
                        }));
                      }}
                      placeholder='Select...'
                      openOnFocus
                      selection
                      value={playAllExtendData.isAllowed}
                      options={[
                        { key: "yes", text: "Yes", value: true },
                        { key: "no", text: "No", value: false },
                      ]}
                    />

                  ) : (
                    policyData.playAllAppExtension.isAllowed ? "Yes" : "No"
                  )
                  }
                </Table.Cell>
                <Table.Cell>
                  {isPlayAllExtendEditing ? (
                    <Form
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <input
                        style={{ marginRight: "12px" }}
                        type='text'
                        onInput={(evt) => {
                          const discount = evt.target.value;
                          setPlayAllExtendData((st) => ({
                            ...st, discount
                          }))
                        }
                        }
                        value={playAllExtendData.discount}
                      />
                      %
                    </Form>
                  ) : (
                    policyData.playAllAppExtension.discount
                  )}
                </Table.Cell>
                <Table.Cell>
                  {isPlayAllExtendEditing ? (
                    <Form
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <input
                        style={{ marginRight: "12px" }}
                        type='text'
                        onInput={(evt) => {
                          const bookingWindowInMinutes = evt.target.value;
                          setPlayAllExtendData((st) => ({
                            ...st, bookingWindowInMinutes
                          }))
                        }
                        }
                        value={playAllExtendData.bookingWindowInMinutes}
                      />
                      %
                    </Form>
                  ) : (
                    policyData.playAllAppExtension.bookingWindowInMinutes
                  )}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          {isPlayAllExtendEditing ? (
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Button
                onClick={onPolicyUpdate}
                style={{
                  backgroundColor: "#e8632b",
                  color: "#fff",
                }}
              >
                Save
              </Button>
            </div>
          ) : null}

          <div
            style={{
              marginTop: "22px",
              marginBottom: "10px",
              fontSize: "15px",
              fontWeight: "600",
            }}
          >
            Part Payment
            <Icon
              onClick={() => setEditType("partPayment")}
              name={"pencil alternate"}
              color={isPartPayment ? "grey" : "orange"}
              style={{ marginLeft: "10px" }}
            />
          </div>
          <Table celled padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Allowed</Table.HeaderCell>
                <Table.HeaderCell>AdvancePayment</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  {isPartPayment ? (
                    <Dropdown
                      style={{ fontSize: "12px" }}
                      onChange={(event, { value }) => {
                        setPartPaymentData((st) => ({
                          ...st,
                          isAllowed: value,
                        }));
                      }}
                      placeholder='Select...'
                      openOnFocus
                      selection
                      value={partPaymentData.isAllowed}
                      options={[
                        { key: "yes", text: "Yes", value: true },
                        { key: "no", text: "No", value: false },
                      ]}
                    />

                  ) : (
                    policyData.partPayment.isAllowed ? "Yes" : "No"
                  )}
                </Table.Cell>
                <Table.Cell>
                  {isPartPayment ? (
                    <Form
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <input
                        style={{ marginRight: "12px" }}
                        type='text'
                        onInput={(evt) => {
                          const advancePayment = evt.target.value;
                          setPartPaymentData((st) => ({
                            ...st, advancePayment
                          }))
                        }
                        }
                        value={partPaymentData.advancePayment}
                      />
                      %
                    </Form>
                  ) : (
                    policyData.partPayment.advancePayment
                  )}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          {isPartPayment ? (
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Button
                onClick={onPolicyUpdate}
                style={{
                  backgroundColor: "#e8632b",
                  color: "#fff",
                }}
              >
                Save
              </Button>
            </div>
          ) : null}

          <div
            style={{
              marginTop: "22px",
              marginBottom: "10px",
              fontSize: "15px",
              fontWeight: "600",
            }}
          >
            Maximum Participants
            <Icon
              onClick={() => setEditType("maxCapacity")}
              name={"pencil alternate"}
              color={isExtendEditing ? "grey" : "orange"}
              style={{ marginLeft: "10px" }}
            />
          </div>
          <Table celled padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Number of participants</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>

                <Table.Cell>
                  {isMaxCapacityEditing ? (
                    <Form
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <input
                        style={{ marginRight: "12px" }}
                        type='number'
                        onInput={(evt) => {
                          const numberOfParticipants = evt.target.value;
                          setMaxParticipants(numberOfParticipants)
                        }
                        }
                        value={maxParticipants}
                      />
                      %
                    </Form>
                  ) : (
                    policyData.maxCapacity
                  )}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          {isMaxCapacityEditing ? (
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Button
                onClick={onPolicyUpdate}
                style={{
                  backgroundColor: "#e8632b",
                  color: "#fff",
                }}
              >
                Save
              </Button>
            </div>
          ) : null}
        </div>
      ) : (
        <div style={{ padding: "12px" }}>No policies found!</div>
      )}
    </div>
  );
};

export default SettingsMain;
