import React, { useContext, useEffect, useState } from 'react';
import {
  Button, Table
} from "semantic-ui-react";


import ModalPortal from '../generic/ModalPortal';
import { AppContext } from '../../AppContext';
import { getWalletTransactions } from "../../apiclients/WalletApiClient";
import HorizontalLineWithText from '../generic/HorizontalLineWithText';
const WalletTransaction = (props) => {

  const { showModal, setReload, setMessage, isLoading, editPackage, setShowMessage, selectedPackage } = props;
  console.log("selectedPackage", selectedPackage);
  const context = useContext(AppContext);

  const [transactionsList, setTransactionsList] = useState([]);

  useEffect(() => {
    if (selectedPackage) {
      getWalletTransactionsApi();
    }
  }, [selectedPackage]);

  function calculateDaysDifference(targetDate) {
    // Get the current date
    const now = new Date();

    // Convert the given date to a Date object
    const givenDate = new Date(targetDate.seconds * 1000 + targetDate.nanoseconds / 1000000);

    // Calculate the difference in milliseconds
    const differenceInMillis = now - givenDate;

    // Convert milliseconds to days
    const differenceInDays = Math.floor(differenceInMillis / (1000 * 60 * 60 * 24));

    if (differenceInDays < 0) {
      return 0;
    }
    return differenceInDays;
  }

  function formatTimestamp({ seconds, nanoseconds }, value) {
    // Convert the given timestamp to milliseconds
    const milliseconds = seconds * 1000 + nanoseconds / 1e6;

    // Create a Date object from the milliseconds
    const date = new Date(milliseconds);

    // Format the date as "DD-MMM-YYYY"
    const optionsDate = { day: '2-digit', month: 'short', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-GB', optionsDate).replace(/ /g, '-');

    // Format the time as "h:mmAM/PM"
    const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: true };
    const formattedTime = date.toLocaleTimeString('en-US', optionsTime);

    // Combine the formatted date and time
    if (value) {
      return formattedDate
    }
    return formattedTime
  }


  const modalContent = (
    <div style={{ paddingTop: "16px", height: '400px', overflowY: 'auto' }}>
      <div style={{padding:"10px"}}>
        <Table celled padded>
          <Table.Body>
            <Table.Row >
              <Table.Cell>
                <span style={{
                  fontWeight: 700
                }}>Package Name</span>
              </Table.Cell>
              <Table.Cell>{selectedPackage?.packageName}</Table.Cell>
              <Table.Cell>
                <span style={{
                  fontWeight: 700
                }}> Outstanding</span>
              </Table.Cell>
              <Table.Cell>{selectedPackage?.outstandingCredits} </Table.Cell>
              <Table.Cell>
                <span style={{
                  fontWeight: 700
                }}> Valid Till</span>
              </Table.Cell>
              <Table.Cell>{formatTimestamp(selectedPackage?.validTill, true)} </Table.Cell>
              <Table.Cell>
                <span style={{
                  fontWeight: 700
                }}> Hours/Day</span>
              </Table.Cell>
              <Table.Cell> {
                selectedPackage?.hoursPerDay
              }</Table.Cell>
            
            </Table.Row>
            <Table.Row >
         
              <Table.Cell>
                <span style={{
                  fontWeight: 700
                }}> Advance Booking</span>
              </Table.Cell>
              <Table.Cell>{selectedPackage?.advanceBooking
              } </Table.Cell>
                <Table.Cell>
                <span style={{
                  fontWeight: 700
                }}> Applicable Arenas</span>
              </Table.Cell>
              <Table.Cell>{selectedPackage.arenas &&selectedPackage.arenas.length>0 && selectedPackage.arenas.map(x => x.arenaName + ', ')}</Table.Cell>

              <Table.Cell>
                <span style={{
                  fontWeight: 700
                }}>Applicable Sport</span>
              </Table.Cell>
              <Table.Cell colSpan='3'> { selectedPackage.sports && selectedPackage.sports.length>0 && selectedPackage.sports.map(x => x.sportName + ', ')}</Table.Cell>
              {/* <Table.Cell>  </Table.Cell> */}
            </Table.Row>
          </Table.Body>
        </Table>
      </div>

      <HorizontalLineWithText text="Transactions" />
      <div style={{padding:"10px"}}>
      <Table celled padded>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>S.No</Table.HeaderCell>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Time</Table.HeaderCell>
            <Table.HeaderCell>Credits</Table.HeaderCell>
            <Table.HeaderCell>Transaction</Table.HeaderCell>
            <Table.HeaderCell>Transaction Id</Table.HeaderCell>
            <Table.HeaderCell>Created By</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {transactionsList.length > 0 ? transactionsList.map((x, index) => {

            return (
              <Table.Row key={x.id}>
                <Table.Cell>{index + 1}</Table.Cell>
                <Table.Cell>{formatTimestamp(x.createdDate, true)}</Table.Cell>
                <Table.Cell>{formatTimestamp(x.createdDate, false)}</Table.Cell>
                <Table.Cell><span style={x.totalCredits>0 ? {color:"green"}:{color:"red"}}>{x.totalCredits}</span> </Table.Cell>
                <Table.Cell>{x.walletTransactionType}</Table.Cell>
                <Table.Cell>{x.transactionId}</Table.Cell>
                <Table.Cell>{x.createdBy?.userName}</Table.Cell>
              </Table.Row>
            );
          }) : <Table.Row>
            <Table.Cell colSpan='9' style={{ textAlign: 'center' }}>No data found</Table.Cell>
          </Table.Row>}
        </Table.Body>
      </Table>
      </div>
     
    </div>
  );

  const renderAction = () => {
    return (
      <React.Fragment>
        <Button onClick={() => showModal(false)} className='ui button'>Cancel</Button>
      </React.Fragment>
    );
  };

  const getWalletTransactionsApi = () => {

    isLoading(true);
    // showModal(false);
    getWalletTransactions(
      context.selectedFacility,
      selectedPackage.mobile,
      selectedPackage.id
    ).then((response) => {
      const newTransactionsList = response?.data?.
        walletTransactionData;
      console.log("newTransactionsList", newTransactionsList);
      setTransactionsList(newTransactionsList)
      // setPackages(pacakgeData);
      // setisLoading(false);
      // setReload(false);
      isLoading(false);
    }).catch((error) => {
      isLoading(false);
    });
  }


  return (
    <>
      <ModalPortal
        title={'Wallet Details'}
        content={modalContent}
        action={renderAction()}
        onDismiss={() => showModal(false)}
      />
    </>

  );


}

export default WalletTransaction;
