import React from "react";
import { topSectionData, footerSection, NavSection } from "./HeaderAndFooter";
import {Helmet} from "react-helmet";
import { Fade, Zoom, } from 'react-reveal';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const contentSection = (
  <>
    <div class='dispfbanner'>
      <div class='bannerarea'>
        <h4 data-aos='zoom-in-down'>PLAY OR LEARN</h4>
        <h1 data-aos='fade-up'>
          Badminton -  Best Infrastructure. Professional Coaching.
        </h1>
        <div class='btarea' data-aos='zoom-in'></div>
      </div>
    </div>
    <div class='aboutarea ' data-aos='zoom-in'>
      <div class='aboutbox '>
        <div class='boxes '>
          <svg viewBox='0 0 55.558 47.814'>
            <use href='#stadium'></use>
          </svg>
          <h5> Best in Class Infra</h5>
        </div>
        <div class='boxes '>
          <svg>
            <use href='#park'></use>
          </svg>
          <h5> 4 Cities</h5>
        </div>
        <div class='boxes '>
          <svg>
            <use href='#badmintonico'></use>
          </svg>
          <h5> 31 + Courts </h5>
        </div>
      </div>
    </div>
  </>
);

const mainSection = (
  <>
    <main>
    <section class='partnersec '>
        <div class='container'>
          <div class='innerbx pb-5'>
            <div class='contentarea '>
              <Zoom>  <h4 >PlayAll</h4>
                <h2 > PlayAll Badminton Arena - Sec 141 Noida</h2>
                <p>With 6 Indoor <b>Badminton Courts</b> (Synthetic Mats), PlayAll is one of the best <b>Badminton Academy</b> in Noida. With Amenities like Ample Parking, online booking, and LED lighting  we ensure that your Badminton experience is hassel free and awesome. </p>

              </Zoom>
              <span class='address'>
                Address - Olympus Sports Club Plot-96, Gram Shahdara, near Chauhan Market, Sector 141, Noida, Uttar Pradesh 201305| Contact - 011-411-70771
              </span>

              <div class='btarea mb-3'>
                <a
                  href='https://maps.app.goo.gl/JEp2FY4xTLQ7tKGw5'
                  class='btwhite1'
                >

                  <svg width='34' height='41'>
                    <use href='#googleMap'></use>
                  </svg>
                  <Fade left> Directions</Fade>
                </a>
              </div>

              <div class='sportsavailble'>
                <Zoom>Book via</Zoom>
                <div class='divider'></div>
                <a
                  href='https://play.google.com/store/apps/details?id=com.playallrn'
                  class='btorng'
                  style={{ background: "#000000" }}
                  target="_blank"
                >
                  {" "}
                  <img alt='' src='images/playStore.png' style={{ height: "120px" }} />
                </a>


                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://apps.apple.com/us/app/play-all/id6480510323'
                  style={{ background: "#000000" }}

                  target="_blank"
                >
                  <img src='images/appStore.png' alt='' style={{ height: "120px" }} />
                </a>
              </div>

              
            </div>
            <div class=' rightbx startplayarea1 d-flex justify-content-center'>
              <div class='startplaybox'>
                <Fade >
                  <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={1400} swipeable={true} emulateTouch={true}>

                  <img src='images/gallery/Faridabad_88_1.png' alt='badminton academy' />
                    <img src='images/gallery/Faridabad_88_2.png' alt='badminton coaching' />
                    <img src='images/gallery/Faridabad_88_3.png' alt='badminton court near me' />

                  </Carousel></Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
    <section class='partnersec badmintondeco'>
        <div className='container'>
          <div class='innerbx pb-5'>
            <div class=' rightbx startplayarea d-flex justify-content-center'>
              <div class='startplaybox'>
                <Fade >
                  <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={1400} swipeable={true} emulateTouch={true}>

                    <img src='images/gallery/gaurs_badminton_0.png' alt='badminton court' />
                    <img src='images/gallery/gaurs_badminton_1.png' alt='badminton academy' />
                    <img src='images/gallery/gaurs_badminton_2.png' alt='badminton coaching' />

                  </Carousel></Fade>

              </div>
            </div>
            <div class='contentarea '>
              <Zoom>
                <h4 > PlayAll</h4>
                <h2 >Gaur City - Greater Noida</h2>
                <p>With 8 Indoor <b>Badminton Courts</b> (Synthetic Mats), PlayAll is one of the best <b>Badminton Academy</b> in Greater Noida. With Amenities like Ample Parking, Cafeteria, and Sports Shop, we ensure that your Badminton experience is hassel free and awesome. </p></Zoom>
                <span class='address'>
                Address - Gaur City Sports Complex, E Block, Gaur City 1, Sector 4, Greater Noida, Uttar Pradesh 201009| Contact - 01141187822 | 01141170333
              </span>
              <div class='btarea mb-3'>
              <a
                  href='https://goo.gl/maps/XRjs1h6FZjYUoPtv7'
                  class='btwhite1'
                >
                  <svg width='34' height='41'>
                    <use href='#googleMap'></use>
                  </svg>
                  <Zoom >Directions</Zoom>
                </a>
              </div>


              <div class='sportsavailble'>
                <Zoom>Book via</Zoom>
                <div class='divider'></div>
                <a
                  href='https://play.google.com/store/apps/details?id=com.playallrn'
                  class='btorng'
                  style={{ background: "#000000" }}
                  target="_blank"
                >
                  {" "}
                  <img alt='' src='images/playStore.png' style={{ height: "120px" }} />
                </a>


                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://apps.apple.com/us/app/play-all/id6480510323'
                  style={{ background: "#000000" }}

                  target="_blank"
                >
                  <img src='images/appStore.png' alt='' style={{ height: "120px" }} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class='partnersec '>
        <div class='container'>
          <div class='innerbx pb-5'>
            <div class='contentarea '>
              <Zoom>  <h4 >PlayAll</h4>
                <h2 > @ Modern School - Sector 11 Noida</h2>
                <p>With 4 Indoor <b>Badminton Courts</b> (Synthetic Mats), PlayAll is one of the best <b>Badminton Academy</b> in Noida. With Amenities like Ample Parking, online booking, and LED lighting  we ensure that your Badminton experience is hassel free and awesome. </p>

              </Zoom>
              <span class='address'>
                Address - S1 Modern School, R Block, Sector 11, Noida, Uttar Pradesh 201301| Contact - 01141169060
              </span>

              <div class='btarea mb-3'>
                <a
                  href='https://g.page/modern_noida?share'
                  class='btwhite1'
                >

                  <svg width='34' height='41'>
                    <use href='#googleMap'></use>
                  </svg>
                  <Fade left> Directions</Fade>
                </a>
              </div>

              <div class='sportsavailble'>
                <Zoom>Book via</Zoom>
                <div class='divider'></div>
                <a
                  href='https://play.google.com/store/apps/details?id=com.playallrn'
                  class='btorng'
                  style={{ background: "#000000" }}
                  target="_blank"
                >
                  {" "}
                  <img alt='' src='images/playStore.png' style={{ height: "120px" }} />
                </a>


                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://apps.apple.com/us/app/play-all/id6480510323'
                  style={{ background: "#000000" }}

                  target="_blank"
                >
                  <img src='images/appStore.png' alt='' style={{ height: "120px" }} />
                </a>
              </div>

              
            </div>
            <div class=' rightbx startplayarea1 d-flex justify-content-center'>
              <div class='startplaybox'>
                <Fade >
                  <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={1400} swipeable={true} emulateTouch={true}>

                    <img src='images/gallery/modern_badminton_0.png' alt='badminton court' />
                    <img src='images/gallery/modern_badminton_1.png' alt='badminton academy' />
                    <img src='images/gallery/modern_badminton_2.png' alt='badminton coaching' />

                  </Carousel></Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class='partnersec badmintondeco'>
        <div className='container'>
          <div class='innerbx pb-5'>
            <div class=' rightbx startplayarea d-flex justify-content-center'>
              <div class='startplaybox'>
                <Fade >
                  <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={1400} swipeable={true} emulateTouch={true}>

                    <img src='images/gallery/gurgaon_badminton_0.png' alt='badminton court' />
                    <img src='images/gallery/gurgaon_badminton_5.png' alt='badminton academy' />
                    <img src='images/gallery/gurgaon_badminton_3.png' alt='badminton coaching' />
                    <img src='images/gallery/gurgaon_badminton_4.png' alt='badminton court near me' />
                    <img src='images/gallery/gurgaon_badminton_2.png' alt='badminton academy near me' />
                    <img src='images/gallery/gurgaon_badminton_1.png' alt='badminton school' />

                  </Carousel></Fade>

              </div>
            </div>
            <div class='contentarea '>
              <Zoom>
                <h4 > PlayAll</h4>
                <h2 >Gurugram</h2>
                <p>With 6 Indoor <b>Badminton Courts</b> (Synthetic Mats on Wooden Flooring), Play All is one of the best <b>Badminton Academy</b> in Gurugram. With Amenities like Ample Parking, Shower, Cafeteria, and Sports Shop, we ensure that your Badminton experience is hassel free and awesome. </p></Zoom>
              <span class='address'>
                Address - CRPF Road, Opposite Heritage School, Sector 62, Gurugram, 122005, Haryana | Contact - 01141179906 | 01141169010
              </span>
              <div class='btarea mb-3'>


                <a href='https://goo.gl/maps/ehmfU6Qtyw82  ' class='btwhite1'
                  target="_blank">

                  <svg width='34' height='41'>
                    <use href='#googleMap'></use>
                  </svg>
                  <Zoom >Directions</Zoom>
                </a>
              </div>


              <div class='sportsavailble'>
                <Zoom>Book via</Zoom>
                <div class='divider'></div>
                <a
                  href='https://play.google.com/store/apps/details?id=com.playallrn'
                  class='btorng'
                  style={{ background: "#000000" }}
                  target="_blank"
                >
                  {" "}
                  <img alt='' src='images/playStore.png' style={{ height: "120px" }} />
                </a>


                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://apps.apple.com/us/app/play-all/id6480510323'
                  style={{ background: "#000000" }}

                  target="_blank"
                >
                  <img src='images/appStore.png' alt='' style={{ height: "120px" }} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class='partnersec '>
        <div class='container'>
          <div class='innerbx pb-5'>
            <div class='contentarea '>
              <Zoom>  <h4 >PlayAll</h4>
                <h2 >Faridabad</h2>
                <p>With 7 Indoor <b>Badminton Courts</b> (Synthetic Mats on Wooden Flooring), Play All is one of the best <b>Badminton Academy</b> in Faridabad. With Amenities like Ample Parking, Shower, Rental Shoes, and Sports Shop, we ensure that your Badminton experience is hassel free and awesome. </p>

              </Zoom>
              <span class='address'>
                Address - Near Sai Mandir, Sector 86, Faridabad, Haryana 121002| Contact - 01141187780
              </span>

              <div class='btarea mb-3'>
                <a
                  href='https://g.page/play-all-sports-faridabad?share'
                  class='btwhite1'
                >

                  <svg width='34' height='41'>
                    <use href='#googleMap'></use>
                  </svg>
                  <Fade left> Directions</Fade>
                </a>
              </div>

              <div class='sportsavailble'>
                <Zoom>Book via</Zoom>
                <div class='divider'></div>
                <a
                  href='https://play.google.com/store/apps/details?id=com.playallrn'
                  class='btorng'
                  style={{ background: "#000000" }}
                  target="_blank"
                >
                  {" "}
                  <img alt='' src='images/playStore.png' style={{ height: "120px" }} />
                </a>


                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://apps.apple.com/us/app/play-all/id6480510323'
                  style={{ background: "#000000" }}

                  target="_blank"
                >
                  <img src='images/appStore.png' alt='' style={{ height: "120px" }} />
                </a>
              </div>

              
            </div>
            <div class=' rightbx startplayarea1 d-flex justify-content-center'>
              <div class='startplaybox'>
                <Fade >
                  <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={1400} swipeable={true} emulateTouch={true}>

                    <img src='images/gallery/faridabad_badminton_0.png' alt='badminton court' />
                    <img src='images/gallery/faridabad_badminton_1.png' alt='badminton school' />
                    <img src='images/gallery/faridabad_badminton_2.png' alt='badminton academy near me' />
                    <img src='images/gallery/faridabad_badminton_3.png' alt='badminton coaching' />
                    <img src='images/gallery/faridabad_badminton_4.png' alt='badminton court near me' />

                  </Carousel></Fade>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class='partnersec badmintondeco'>
        <div class='container'>
          <div class='innerbx'>
            <div class=' rightbx startplayarea d-flex justify-content-center'>
            <div class='startplaybox'>
                <Fade >
                  <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={1400} swipeable={true} emulateTouch={true}>

                    <img src='images/gallery/noida_badminton_0.png' alt='badminton court' />
                    <img src='images/gallery/noida_badminton_5.png' alt='badminton academy' />
                    <img src='images/gallery/noida_badminton_3.png' alt='badminton coaching' />
                    <img src='images/gallery/noida_badminton_4.png' alt='badminton court near me' />
                    <img src='images/gallery/noida_badminton_2.png' alt='badminton academy near me' />
                    <img src='images/gallery/noida_badminton_1.png' alt='badminton school' />

                  </Carousel></Fade>
              </div>
            </div>
            <div class='contentarea '>
              <Zoom><h4 >PlayAll </h4>
                <h2 >Noida </h2>
                <p>With 4 Indoor <b>Badminton Courts</b> (Synthetic Mats on Wooden Flooring), Play All is one of the best <b>Badminton Academy</b> in Noida. With Amenities like Ample Parking, Rental Shoes, and Sports Shop, we ensure that your Badminton experience is hassel free and awesome. </p>

                </Zoom>
              <span class='address'>
                Near Noida Pet Clinic, Sector 73, Noida, Uttar Pradesh 201301 | Contact - 01140849901 | 01141169001
              </span>
              <div class='btarea mb-3'>
                <a href='https://goo.gl/maps/MHyikEmmcw22' class='btwhite1'>

                <svg width='34' height='41'>
                    <use href='#googleMap'></use>
                  </svg>
                  <Fade right> Directions</Fade>
                </a>
              </div>
           
              <div class='sportsavailble'>
                <Zoom>Book via</Zoom>
                <div class='divider'></div>
                <a
                  href='https://play.google.com/store/apps/details?id=com.playallrn'
                  class='btorng'
                  style={{ background: "#000000" }}
                  target="_blank"
                >
                  {" "}
                  <img alt='' src='images/playStore.png' style={{ height: "120px" }} />
                </a>


                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://apps.apple.com/us/app/play-all/id6480510323'
                  style={{ background: "#000000" }}

                  target="_blank"
                >
                  <img src='images/appStore.png' alt='' style={{ height: "120px" }} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section class='partnersec '>
        <div class='container'>
          <div class='innerbx pb-5'>
            <div class='contentarea '>
              <Zoom>  <h4 >PlayAll</h4>
                <h2 > @ Blue Angels Global School - Sector 17 Faridabad</h2>
                <p>With 2 Indoor <b>Badminton Courts</b> (Synthetic Mats), PlayAll is one of the best <b>Badminton Academy</b> in Faridabad. With Amenities like Ample Parking, online booking, and LED lighting  we ensure that your Badminton experience is hassel free and awesome. </p>

              </Zoom>
              <span class='address'>
                Address -  Blue Angels Global School Charmwood Village, Eros Garden, Faridabad, Haryana 121009| Contact - 011-411-69588 
              </span>

              <div class='btarea mb-3'>
                <a
                  href='https://goo.gl/maps/QGtZU2gGU9qe2Ubo7'
                  class='btwhite1'
                >

                  <svg width='34' height='41'>
                    <use href='#googleMap'></use>
                  </svg>
                  <Fade left> Directions</Fade>
                </a>
              </div>

              <div class='sportsavailble'>
                <Zoom>Book via</Zoom>
                
                <div class='divider'></div>
                <a
                  // href='https://playo.co/venues/sector-11-noida-ncr/play-all-@-modern-school-sector-11-noida-ncr'
                  class='btorng'

                  target="_blank"
                >
                  {" "}
                  <img alt='' src='images/playo_logo.png' />
                </a>
                
            
                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  // href='https://hudle.in/venues/play-all-modern-school-noida/930734'
                  
                  target="_blank"
                >
                  <img src='images/huddle.svg' alt='' />
                </a>
              </div>

              
            </div>
            <div class=' rightbx startplayarea1 d-flex justify-content-center'>
              <div class='startplaybox'>
                <Fade >
                  <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={1400} swipeable={true} emulateTouch={true}>

                    <img src='images/gallery/coming_soon_badminton.png' alt='badminton court in faridabad' />
                    {/* <img src='images/gallery/modern_badminton_1.png' alt='badminton academy in faridabad' />
                    <img src='images/gallery/modern_badminton_2.png' alt='badminton coaching in faridabad' /> */}

                  {/* </Carousel></Fade>
              </div>
            </div>
          </div>
        </div>
      </section> */} 

      <section class='partnersec '>
        <div class='container'>
          <div class='innerbx'>
            <div class=' rightbx startplayarea d-flex justify-content-center'>
            <div class='startplaybox'>
                <Fade >
                  <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={1400} swipeable={true} emulateTouch={true}>

                    <img src='images/gallery/Faridabad_88_1.png' alt='badminton academy' />
                    <img src='images/gallery/Faridabad_88_2.png' alt='badminton coaching' />
                    <img src='images/gallery/Faridabad_88_3.png' alt='badminton court near me' />

                  </Carousel></Fade>
              </div>
            </div>
            <div class='contentarea '>
              <Zoom><h4 >PlayAll Badminton Arena</h4>
                <h2 >Sector 88 - Faridabad </h2>
                <p>With 4 Indoor <b>Badminton Courts</b>, PlayAll is one of the best <b>Badminton Academy</b> in Faridabad. With Amenities like Ample Parking, Rental Shoes, and Sports Shop, we ensure that your Badminton experience is hassel free and awesome. </p>

                </Zoom>
              <span class='address'>
              opp. RPS City, Pataakhe Wali Gali, RPS City, Sector 88, Faridabad, Haryana 121002 | Contact - 011-411-70888
              </span>
              <div class='btarea mb-3'>
                <a href='https://maps.app.goo.gl/bPGw3EufQKovXFw47' class='btwhite1'>

                <svg width='34' height='41'>
                    <use href='#googleMap'></use>
                  </svg>
                  <Fade right> Directions</Fade>
                </a>
              </div>
           
              <div class='sportsavailble'>
                <Zoom>Book via</Zoom>
                <div class='divider'></div>
                <a
                  href='https://play.google.com/store/apps/details?id=com.playallrn'
                  class='btorng'
                  style={{ background: "#000000" }}
                  target="_blank"
                >
                  {" "}
                  <img alt='' src='images/playStore.png' style={{ height: "120px" }} />
                </a>


                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://apps.apple.com/us/app/play-all/id6480510323'
                  style={{ background: "#000000" }}

                  target="_blank"
                >
                  <img src='images/appStore.png' alt='' style={{ height: "120px" }} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class=' techsection badmintondeco'>
        <div class='container'>
          <div class='headarea'>
            <h4 class=' sectiontitle '>
              PlayAll
            </h4>
            <Fade bottom cascade><h2 class=' sectionhead '>
              One-Stop-Destination for Badminton Lovers
            </h2></Fade>
          </div>
          <div class='techarea sportsarea'>
            <div class='sportsbox '>
              <div class='iconarea'>
              <svg viewBox="0 0 55.558 47.814">
            <use href="#data"></use>
          </svg>
              </div>
              <Zoom><h4 >Pay & Play | Memberships</h4></Zoom>
              <span>BOOK YOUR COURT IN ADVANCE, SIT BACK & RELAX!</span>
            </div>
            <div class='sportsbox '>
              <div class='iconarea'>
                <svg>
                  <use href='#badmintonico'></use>
                </svg>
              </div>
              <Zoom><h4 >Coaching</h4></Zoom>
              <span>
              CURRICULUM-DRIVEN COACHING BY PROFESSIONAL COACHES.

              </span>
            </div>
            <div class='sportsbox '>
              <div class='iconarea'>
              <svg viewBox='0 0 55.558 47.814'>
            <use href='#stadium'></use>
          </svg>
              </div>
              <Zoom><h4 >Best-in-class Infra</h4></Zoom>
              <span>
                BWF APPROVED FLOORING, INTERNATIONAL LIGHTING, 30 FT HEIGHT
              </span>
            </div>
           
          </div>
        </div>
      </section>


    </main>
  </>
);

const Badminton = () => {
  return (
    <>
    <Helmet>
            <title>Badminton - Badminton court and Badminton academy near me</title>
            <meta name="description" content="Play all offers best Badminton court and badminton academy. In Noida we have a 4 court indoor badminton academy near sector 73. In Faridabad we have 7 indoor badminton court near sector 86. In Gurgoan we have a 6 court badminton academy near sector 62." />
        </Helmet>
      <div style={{ overflowY: "scroll", height: "calc(100vh)" }}>
        {topSectionData}
        <div class='headbanner startplayingbg' data-aos='fade'>
          <div class='container '>
            <NavSection current={"badminton"} />
            {contentSection}
          </div>
        </div>
        {mainSection}
        {footerSection}
      </div>
    </>
  );
};

export default Badminton;
