import React, { useState, useContext, useEffect } from 'react';
import {
    Button,
    Form, Checkbox, Dropdown
} from "semantic-ui-react";
import { colors } from "@material-ui/core";
import { AppContext } from '../../AppContext';
import ModalPortal from '../generic/ModalPortal';
import DatesSelection from '../common/DatesSelection';
import { addBanner,updateBanner } from '../../apiclients/WalletApiClient';
import moment from 'moment';

const AddBanner = (props) => {
    const { showModal, setReload, setMessage, isLoading,data,edit } = props;
    const context = useContext(AppContext);
    const [formData, setFormData] = useState({
        imageURL:"",
        redirectionURL:"",
        location:{
            lat:"",
            lon:""
        },
        ranking:""
    });
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

     const isSubmitAllowed =formData.imageURL&& formData.redirectionURL && formData.ranking && formData.location.lat&&  formData.location.lon&& startDate && endDate;

//    console.log("data,edit",data,edit)

    useEffect(() => {
        if(edit && data){
            setFormData({
                imageURL:data.imageURL,
                redirectionURL:data.redirectionURL,
                location: data.location,
                ranking:data.ranking
            })
            setStartDate(firestoreTimestampToDate(data.startDate))
            setEndDate(firestoreTimestampToDate(data.endDate))
           
        }
    }, [data]);

    function firestoreTimestampToDate(timestamp) {
        const milliseconds = timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
        return moment(milliseconds).format('YYYY-MM-DD HH:mm:ss'); // Format as desired
      }

    const changeDate = (isStart) => value => {
        if (isStart) {
            setStartDate(value);
        } else {
            setEndDate(value);
        }
    }

   

     

    

     

    const handleChange = (name, value) => {
        setFormData((prevState) => {
          if (name.includes(".")) {
            const [outerKey, innerKey] = name.split(".");
            return {
              ...prevState,
              [outerKey]: {
                ...prevState[outerKey],
                [innerKey]: value,
              },
            };
          }
          return {
            ...prevState,
            [name]: value,
          };
        });
      };
      
//    const handleLocationChange = (name ,value)=>{
//     setFormData((prevState) => ({
//         ...prevState,
//         [name]: value,
//       }));
//    }

    const modalContent = (
        <div>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Field  >
                        <label>Image Url</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            name='imageURL'
                            value={formData.imageURL}
                            placeholder='enter image url'
                             onChange={(e) => handleChange("imageURL",e.target.value)}
                        />
                    </Form.Field>
                    <Form.Field  >

                        <label>Redirection Url</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            name='redirectionURL'
                            value={formData.redirectionURL}
                            placeholder='enter redirection url'
                           onChange={(e) => handleChange("redirectionURL",e.target.value)}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field  >
                        <label>Lat</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            name="location.lat"
                            value={formData.location.lat}
                            placeholder='enter lat'
                            onChange={(e) => handleChange("location.lat",e.target.value)}
                        />
                    </Form.Field>
                    <Form.Field  >

                        <label>Lon</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={formData.location.lon}
                            name="location.lon"
                            placeholder='enter lon'
                            onChange={(e) => handleChange("location.lon",e.target.value)}
                        />
                    </Form.Field>
                </Form.Group>
             
                <Form.Group widths='equal'>
                    <Form.Field  >
                        <DatesSelection
                            startDate={startDate}
                            endDate={endDate}
                            changeDate={changeDate}
                            disablePast={true}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Ranking</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            name='ranking'
                            value={formData.ranking}
                            placeholder='enter ranking'
                            onChange={(e) => handleChange("ranking",e.target.value)}
                        />
                    </Form.Field>

                </Form.Group>
                
            </Form>
        </div>
    );

    const renderAction = () => {
        return (
            <React.Fragment>
                <Button onClick={() => showModal(false)} className='ui button'>Cancel</Button>

                <Button
                     disabled={!isSubmitAllowed}
                    primary onClick={edit ? updateBannerFunction:addBannerFunction}>  Submit</Button>
            </React.Fragment>
        );
    };

    const addBannerFunction = () => {
        isLoading(true);
        showModal(false);
        const createdBy = {
            userName: context.user.userName,
            userId: context.user.userId,
        };
       
       
        let postBody =  {...formData,createdBy,startDate,endDate}
        // postBody.startDate=startDate
        // postBody.endDate=endDate
       
        addBanner(
            postBody
        ).then((response) => {
            if (response.status === 200 || response.status === 201) {
                isLoading(false);
                setMessage({
                    header: "Banner Added",
                    message: "Banner has been added successfully.",
                    color: "green",
                });
            } else {
                isLoading(false);
                setMessage({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                });
            }
            setReload(true);
        }).catch((error) => {

            isLoading(false);
            setMessage({
                header: "Error!",
                message: error.message,
                color: "red",
            });
            // ..
        });


    }

    const updateBannerFunction = () => {
        isLoading(true);
        showModal(false);
        const createdBy = {
            userName: context.user.userName,
            userId: context.user.userId,
        };
 
      
        let postBody =  {...formData,createdBy,startDate,endDate,}
       
      

        updateBanner(
            postBody,data.id
        ).then((response) => {
            if (response.status === 200 || response.status === 201) {
                isLoading(false);
                setMessage({
                    header: "Banner updated",
                    message: "Banner has been updated successfully.",
                    color: "green",
                });
            } else {
                isLoading(false);
                setMessage({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                });
            }
            setReload(true);
        }).catch((error) => {

            isLoading(false);
            setMessage({
                header: "Error!",
                message: error.message,
                color: "red",
            });
            // ..
        });


    }
    
    return (
        <>
            <ModalPortal
                title={edit?'Edit Banner':'Create Banner'}
                content={modalContent}
                action={renderAction()}
                onDismiss={() => showModal(false)}
            />
        </>

    );


}

export default AddBanner;