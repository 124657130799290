import React, { useState, useContext, useRef, useEffect } from 'react';

import { Dropdown, Button } from 'semantic-ui-react';
import { colors } from "@material-ui/core";
import { Bar, Pie } from 'react-chartjs-2';
import GenericLoader from '../generic/GenericLoader';
import { AppContext } from '../../AppContext';
import { getPnPSportsOfArena } from '../../apiclients/BookingApiClient';
import { getSlotWiseOccupancyData } from '../../apiclients/AnalyticsClient';
import DatesSelection from '../common/DatesSelection';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Grid } from '@material-ui/core';

const SlotWiseOccupancy = () => {

    console.log('+++++-----');
    const {
        selectedFacility,
        selectedArena,
        arenaData,
        selectedSportDetails,
        dispatch,
    } = useContext(AppContext);

    const sports = useRef([]);
    const [selectedSport, setSelectedSport] = useState({});
    const [isLoading, setisLoading] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [graphData, setGraphData] = useState();
    const [analyticsData, setAnalyticsData] = useState();
    const [selectDaysOfWeek, setSelectDaysOfWeek] = useState({ value: 'all', text: 'All Days' });

    const daysOfWeek = [
        { value: 'all', text: 'All Days' },
        { value: 'weekdays', text: 'Weekdays' },
        { value: 'weekends', text: 'Weekends' },
    ];// all, weekdays, weekends
    const totalColor = 'rgba(56,121,109)';
    const playAllColor = 'rgba(26,60,54)';
    const hudleColor = 'rgba(86,168,226)';
    const playAllAppColor = 'rgba(138,200,183)';
    const playoColor = 'rgba(208,231,214)';

    const loadData = () => {
        console.log('the data is ', selectedFacility, selectedArena.arenaId, selectedSport.value, startDate, endDate, selectDaysOfWeek.value);
        setisLoading(true);
        return getSlotWiseOccupancyData(
            selectedFacility, selectedArena.arenaId, selectedSport.value, startDate, endDate, selectDaysOfWeek.value
        ).then((response) => {
            const analyticsData = response.data;
            setAnalyticsData(analyticsData);
            let graphsData = createBarItemData(analyticsData);
            setGraphData(graphsData);
            setisLoading(false);
        }).catch(err => {
            setisLoading(false);
        });
    };


    useEffect(() => {
        console.log('the selectedFacility data is ', selectedFacility, );
        if (selectedFacility && selectedArena)
            getPnPSportsOfArena(selectedFacility, selectedArena.arenaId).then(
                (response) => {
                    const sports = response.data;
                    dispatch({
                        type: "addCurrentFacilitySports",
                        payload: sports,
                    });
                }
            );
    }, [selectedFacility, selectedArena.arenaId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        console.log('the arena data is ', arenaData, );
        if (selectedArena && arenaData[selectedArena.arenaId]) {
            const dropdownSportsData = arenaData[selectedArena.arenaId]?.sports.map(
                (x, index) => ({
                    key: x.sportId,
                    value: x.sportId,
                    text: x.sportName,
                })
            );
            sports.current = dropdownSportsData;
            setSelectedSport(dropdownSportsData[0]);
            //   loadData(selectedFacility,selectedArena.arenaId, dropdownSportsData[0] );
        }
    }, [arenaData]); // eslint-disable-line react-hooks/exhaustive-deps


    const changeSport = (event, { value }) => {
        console.log(
            "sports data is " +
            JSON.stringify(
                sports.current.find((facility) => facility.value === value)
            )
        );
        const data = sports.current.find((facility) => facility.value === value);
        setSelectedSport(data);
    };
    const changeDays = (event, { value }) => {

        const data = daysOfWeek.find((x) => x.value === value);
        setSelectDaysOfWeek(data);
    };

    const changeDate = (isStart) => value => {
        console.log('this')
        if (isStart) {
            setStartDate(value);
        } else {
            setEndDate(value);
        }
    }

    const barColor = colors.orange[700];
    const useStyles = makeStyles({
        root: {
            minWidth: 275,
        },
        bullet: {
            display: 'inline-block',
            margin: '0 2px',
            transform: 'scale(0.8)',
        },
        title: {
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        },
    });


    const classes = useStyles();

    const createBarItemData = (data) => {
        let labels = [];
        let dataSet = {
            label: 'Slot Wise Occpupancy',
            data: [],
            borderColor: [playAllColor, hudleColor,playAllAppColor, playoColor, totalColor],
            backgroundColor: [playAllColor, hudleColor,playAllAppColor, playoColor, totalColor],
        };

        if (data && data.slotWiseOccupancy) {
            data.slotWiseOccupancy.forEach(x => {
                labels.push(x.time);
                dataSet.data.push(x.occupancy * 100);
            });
        }
        let barData = {
            labels: labels,
            datasets: [
                dataSet
            ]
        };
        return barData;
    }
    return isLoading ? <GenericLoader /> :
        (<div style={{ padding: "16px" }}>
            <div className='dashboard'>Slot Wise Occupancy</div>
            <div className='selectionFilters'>
                <div>
                    <DatesSelection startDate={startDate} endDate={endDate} changeDate={changeDate}
                        disablePast={false}
                        disableFuture={true} />
                </div>
                <div>
                    <Dropdown
                        onChange={changeSport}
                        placeholder='Select...'
                        openOnFocus
                        selection
                        value={selectedSport && selectedSport.value}
                        options={sports.current}
                    />

                </div>
                <div>
                    <Dropdown
                        onChange={changeDays}
                        placeholder='Select days'
                        openOnFocus
                        selection
                        value={selectDaysOfWeek && selectDaysOfWeek.value}
                        options={daysOfWeek}
                    />
                </div>
                <div>
                    <Button
                        onClick={() => loadData()}
                        style={{
                            marginRight: "10px",
                            backgroundColor: colors.orange[700],
                            color: colors.blue[50],
                        }}
                    >
                        Get Data
                    </Button>
                </div>
            </div>
            <hr />
            {graphData=== undefined ? null :(
              <Bar
              data={graphData}
              width={100}
              height={16}
          />  
            )}
            {/* <Bar
                data={graphData}
                width={100}
                height={16}
            /> */}
            <br/>
            { analyticsData === undefined ? null : 
            <Grid container spacing={9}>
                <Grid item md={3} spacing={3}>
                    <Card className={classes.root}>
                        <CardContent>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Source Wise Booking
                            </Typography>
                            <Typography variant="h5" component="h2">
                                <span style={{ color: "grey" }}>Occupancy - </span>{parseFloat(analyticsData.totalOccupancy * 100).toFixed(2)} %
                                <br />
                                <span style={{ color: "grey" }}>Bookings - </span># {analyticsData.totalBooking}
                            </Typography>
                            <Pie
                                width={30}
                                height={7}
                                data={{
                                    labels: [
                                        'PlayAll',
                                        'Hudle',
                                        'PlayAll App',
                                        'Playo'
                                    ],
                                    datasets: [{
                                        label: 'My First Dataset',
                                        data: [analyticsData.playAllBookings,
                                        analyticsData.hudleBookings,
                                        analyticsData.playAllAppBookings,
                                        analyticsData.playoBookings,],
                                        backgroundColor: [
                                            playAllColor,
                                            hudleColor,
                                            playAllAppColor,
                                            playoColor
                                        ],
                                        hoverOffset: 4
                                    }]
                                }}
                            />
                        </CardContent>
                    </Card>

                </Grid>
                <Grid item md={3} spacing={3}>
                    <Card className={classes.root}>
                        <CardContent>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Type of Bookings
                            </Typography>
                            <Typography variant="h5" component="h2">
                                <span style={{ color: "grey" }}>Memberships - </span>{analyticsData.totalMemberships}
                                <br />
                                <span style={{ color: "grey" }}> Bulk-Booking - </span># {analyticsData.totalBulkBooking}
                            </Typography>
                            <Pie
                                width={30}
                                height={10}
                                data={{
                                    labels: [
                                        'Regular',
                                        'Bulk-Booking',
                                        'Membership',
                                        'Extension',
                                        'Rescheduled'
                                    ],
                                    datasets: [{
                                        label: 'My First Dataset',
                                        data: [analyticsData.totalRegularBookings,
                                        analyticsData.totalBulkBooking,
                                        analyticsData.totalMemberships,
                                        analyticsData.totalExtension,
                                        analyticsData.totalRescheduled,
                                        ],
                                        backgroundColor: [
                                            playAllColor,
                                            hudleColor,
                                            playAllAppColor,
                                            playoColor,
                                            totalColor
                                        ],
                                        hoverOffset: 4
                                    }]
                                }}
                            /> </CardContent>
                    </Card>

                </Grid>
                <Grid item md={3} spacing={3}>
                    <Card className={classes.root}>
                        <CardContent>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                                Type of Blockings
                            </Typography>
                            <Typography variant="h5" component="h2">
                                <span style={{ color: "grey" }}> Blocking % - </span>{parseFloat((analyticsData.totalBlocking / analyticsData.totalSlots) * 100).toFixed(2)} %
                                <br />
                                <span style={{ color: "grey" }}> Blocking - # </span>{analyticsData.totalBlocking}
                            </Typography>
                            <Pie
                                width={30}
                                height={10}
                                data={{
                                    labels: [
                                        'Academy',
                                        'Maintenance',
                                        'Tournament',
                                        'Corporate Event',
                                        'Others'
                                    ],
                                    datasets: [{
                                        label: 'My First Dataset',
                                        data: [analyticsData.totalBlockingAcademy,
                                        analyticsData.totalBlockingMaintenance,
                                        analyticsData.totalBlockingTournament,
                                        analyticsData.totalBlockingCorporateEvent,
                                        analyticsData.totalBlockingOthers,],
                                        backgroundColor: [
                                            playAllColor,
                                            hudleColor,
                                            playAllAppColor,
                                            playoColor
                                        ],
                                        hoverOffset: 4
                                    }]
                                }}
                            />
                        </CardContent>
                    </Card>

                </Grid>
            </Grid>
        }
        </div>
        );
}

export default SlotWiseOccupancy;